.heading{
    text-align: center;
    font-weight: 900;
    color: #221638 ;
}
.title-separator {
    width: 46px;
    height: 3px;
    background-color: #1c87f1;
    align-items: center;
    display: inline-block;
    margin: 0 auto;
    top: 4px;
    display: flex;
    margin-bottom: 50px;
}

@media (max-width: 320px) {
    .heading{
        font-size: 30px;
    }
    a button{
        font-size: 14px!important;
    }
    h5,h6,p,button{
        font-size: 14px!important;
    }
    p a{
        font-size: 14px!important;   
    }
}
