@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap');
#notfound {
    position: relative;
    height: 100vh;
    background: #f2f2f2;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
.notfound .notfound-404 {
    position: relative;
    height: 180px;
    margin-bottom: 20px;
    z-index: -1;
}
.notfound .notfound-404 .h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    /* -webkit-transform: translate(-50%,-50%); */
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    letter-spacing: -20px;
}
.notfound .notfound-404 .h2 {
    font-family: montserrat,sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #030005;
    text-transform: uppercase;
    letter-spacing: 13px;
    margin: 0;
}
@media (max-width : 574px) {

    .notfound .notfound-404 .h1{
       font-size: 40px;
       letter-spacing: 0px;
       
   }
   .notfound .notfound-404 .h2{
       font-size: 20px;
   }
    
   .profile1{
        padding: 0px;
    }
}