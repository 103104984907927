@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap);
:root{

  --font-size-h : 40px ;
  --font-size-p : 16px;
  padding : 0px;
  margin : 0px;
  box-sizing: border-box;
  font-size: 64.6%;

}

body {
  margin: 0;
  
  font-family: 'Lato', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .css-h2fnfe{
  min-height: 20px !important;
  height: 30px !important;
 }

/* h1{
  font-size: var(--font-size-h) !important;
  
}

h2{
  font-size : calc(var(--font-size-h) - 5) !important; 
}
 


h4{
  font-size : calc(var(--font-size-h) - 10) !important;
}

h5{
  font-size : calc(var(--font-size-h) - 15 ) !important;
}

h6{
  font-size : calc( var(--font-size-h) - 20) !important;
}

p{
  font-size: var(--font-size-p) !important; 
}

@media (max-width : 500px) {

  :root{
    --font-size-h : 30px !important;
    --font-size-p : 10px;
  }
  
} */

::-webkit-scrollbar{
  background-color: #FAFAFE;
  width: 5px;
  height: 5px;
  border-radius: 10px;

}
 
::-webkit-scrollbar-thumb{
  width: 5px;
  height: 5px;

  background-color: #6C70F9;
  border-radius: 10px;

}
.heading{
    text-align: center;
    font-weight: 900;
    color: #221638 ;
}
.title-separator {
    width: 46px;
    height: 3px;
    background-color: #1c87f1;
    align-items: center;
    display: inline-block;
    margin: 0 auto;
    top: 4px;
    display: flex;
    margin-bottom: 50px;
}

@media (max-width: 320px) {
    .heading{
        font-size: 30px;
    }
    a button{
        font-size: 14px!important;
    }
    h5,h6,p,button{
        font-size: 14px!important;
    }
    p a{
        font-size: 14px!important;   
    }
}

#loader{
    position: fixed;
    width: 100px;
    height: 100px;
    display:inline-block;
    top: 50%;
    left: 50%;
  }
  .loader{
    position: fixed;
    text-align: center;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    min-width: 100%;
    opacity: .8;
  }
  
#notfound {
    position: relative;
    height: 100vh;
    background: #f2f2f2;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
.notfound .notfound-404 {
    position: relative;
    height: 180px;
    margin-bottom: 20px;
    z-index: -1;
}
.notfound .notfound-404 .h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    /* -webkit-transform: translate(-50%,-50%); */
    transform: translate(-50%,-50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    letter-spacing: -20px;
}
.notfound .notfound-404 .h2 {
    font-family: montserrat,sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #030005;
    text-transform: uppercase;
    letter-spacing: 13px;
    margin: 0;
}
@media (max-width : 574px) {

    .notfound .notfound-404 .h1{
       font-size: 40px;
       letter-spacing: 0px;
       
   }
   .notfound .notfound-404 .h2{
       font-size: 20px;
   }
    
   .profile1{
        padding: 0px;
    }
}
