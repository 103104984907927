#loader{
    position: fixed;
    width: 100px;
    height: 100px;
    display:inline-block;
    top: 50%;
    left: 50%;
  }
  .loader{
    position: fixed;
    text-align: center;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    min-width: 100%;
    opacity: .8;
  }
  