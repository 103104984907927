@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');


:root{

  --font-size-h : 40px ;
  --font-size-p : 16px;
  padding : 0px;
  margin : 0px;
  box-sizing: border-box;
  font-size: 64.6%;

}

body {
  margin: 0;
  
  font-family: 'Lato', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .css-h2fnfe{
  min-height: 20px !important;
  height: 30px !important;
 }

/* h1{
  font-size: var(--font-size-h) !important;
  
}

h2{
  font-size : calc(var(--font-size-h) - 5) !important; 
}
 


h4{
  font-size : calc(var(--font-size-h) - 10) !important;
}

h5{
  font-size : calc(var(--font-size-h) - 15 ) !important;
}

h6{
  font-size : calc( var(--font-size-h) - 20) !important;
}

p{
  font-size: var(--font-size-p) !important; 
}

@media (max-width : 500px) {

  :root{
    --font-size-h : 30px !important;
    --font-size-p : 10px;
  }
  
} */

::-webkit-scrollbar{
  background-color: #FAFAFE;
  width: 5px;
  height: 5px;
  border-radius: 10px;

}
 
::-webkit-scrollbar-thumb{
  width: 5px;
  height: 5px;

  background-color: #6C70F9;
  border-radius: 10px;

}